import React from 'react'
import { navigate } from 'gatsby'
import { useMediaQuery } from 'react-responsive'

import * as S from './index.styles'
import * as I from '../shared/Icons'
import { PRIMARY } from '../../constants/colors'
import {
  CONTACT,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  SUPPORT,
  TERMS,
  PRIVACY,
  CLIENT_NAME,
} from '../../constants/routes'
import { TABLET_WIDTH } from '../../constants/measurements'

const Footer = () => {
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${TABLET_WIDTH}px)`,
  })

  return (
    <S.Container>
      <S.Row>
        <S.Column style={{ flex: 3 }}>
          <S.Primary onClick={() => navigate(CONTACT)}>
            Contact {CLIENT_NAME}
          </S.Primary>
        </S.Column>
        <S.Column
          style={{ flex: 3, flexDirection: 'row', justifyContent: 'center' }}
        >
          <S.Primary
            style={{ textAlign: 'center' }}
            onClick={() => navigate(PRIVACY)}
          >
            Privacy Policy
          </S.Primary>
        </S.Column>
        <S.Column
          style={{ flex: 3, flexDirection: 'row', justifyContent: 'flex-end' }}
        >
          <S.ItemsWrap>
            {!isTabletOrMobile && <S.Secondary>Stay in Touch!</S.Secondary>}
            <a href={INSTAGRAM_LINK}>
              <S.IconWrap style={{ paddingRight: 12 }}>
                <I.Instagram color={PRIMARY} />
              </S.IconWrap>
            </a>
            <a href={FACEBOOK_LINK}>
              <S.IconWrap>
                <I.Facebook color={PRIMARY} />
              </S.IconWrap>
            </a>
          </S.ItemsWrap>
        </S.Column>
      </S.Row>
      <S.Row style={{ paddingBottom: '3rem' }}>
        <S.Column style={{ flex: 3 }}>
          <S.Primary onClick={() => navigate(SUPPORT)}>
            Contact Web Support
          </S.Primary>
        </S.Column>
        <S.Column
          style={{ flex: 3, flexDirection: 'row', justifyContent: 'center' }}
        >
          <S.Primary
            style={{ textAlign: 'center' }}
            onClick={() => navigate(TERMS)}
          >
            Terms and Conditions
          </S.Primary>
        </S.Column>
        <S.Column
          style={{ flex: 3, flexDirection: 'row', justifyContent: 'flex-end' }}
        >
          <S.Primary style={{ cursor: 'default', textAlign: 'right' }}>
            Follow us on Instagram and Facebook!
          </S.Primary>
        </S.Column>
      </S.Row>
      <S.Primary style={{ textAlign: 'center', cursor: 'default' }}>
        {CLIENT_NAME}. All Rights Reserved.
      </S.Primary>
      <S.Primary style={{ textAlign: 'center', cursor: 'default' }}>
        Powered by <span style={{ fontStyle: 'italic' }}>Solin</span>
      </S.Primary>
    </S.Container>
  )
}

export default Footer
