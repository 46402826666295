import React from 'react'
import s from 'styled-components'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'

import { WHITE } from '../../../constants/colors'
import { CLIENT_NAME, HOME_ROUTE } from '../../../constants/routes'
import { BOLD_FONT_WEIGHT } from '../../../constants/fonts'
import { maxWidth, PHONE, PHONE_WIDTH } from '../../../constants/measurements'

// eslint-disable-next-line @typescript-eslint/no-var-requires

const StyledLink = s(Link)`
  text-decoration: none !important;
  color: ${WHITE} !important;
  font-weight: ${BOLD_FONT_WEIGHT};
`

const LogoTag = s.img`
  padding-top: 4px;
  height: 48px;
  font-size: 1.2rem;
  margin-bottom: 0;
  width: auto;
  display: inline-block;

  ${maxWidth(PHONE)} {
    height: 24px;
    margin-top: 0.4rem;
  }
`

const logo = process.env.GATSBY_CLIENT_LOGO
const logoAlt = process.env.GATSBY_CLIENT_LOGO_ALT || logo

export const Logo = () => {
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${PHONE_WIDTH}px)`,
  })

  return (
    <StyledLink to={HOME_ROUTE}>
      <LogoTag src={isTabletOrMobile ? logoAlt : logo} alt={CLIENT_NAME} />
    </StyledLink>
  )
}
