import React, { ReactElement, useEffect, useState } from 'react'
import s from 'styled-components'
import { Link } from 'gatsby'

import {
  maxWidth,
  minWidth,
  PHONE,
  DESKTOP,
  SHORT_ANIMATION_DURATION,
  M2,
  M1,
  M3,
} from '../../../constants/measurements'
import { PRIMARY, SECONDARY } from '../../../constants/colors'
import {
  PROFILE_ROUTE,
  MORE_ROUTE,
  WORKOUTS_ROUTE,
  LOGIN_ROUTE,
  HOME_ROUTE,
  DASHBOARD,
  TRAINER_SCHEDULE,
  BASIC_LIVE_SCHEDULE,
  TRAINER_UPLOAD_VIDEO,
} from '../../../constants/routes'
import { MEDIUM_FONT_WEIGHT } from '../../../constants/fonts'
import { BtnLink } from '..'
import { EBtnSize, EBtnKind } from '../Btn'
import * as session from '../../../util/session'
import {
  AdditionalHeaderLinks,
  fetchAdditionalHeaderLinks,
} from '../../../util/fetch/additionalLinks'

const LIVE_FEATURE_TOGGLE =
  process.env.GATSBY_LIVE_FEATURE_TOGGLE === 'true' ? true : false

const HIDE_LIVE_MENU_LINK =
  process.env.GATSBY_HIDE_LIVE_MENU_LINK === 'true' ? true : false

const LANDING_PAGE_ONLY_TOGGLE =
  process.env.GATSBY_LANDING_PAGE_ONLY_TOGGLE === 'true' ? true : false

const GATSBY_API_BASE_CLIENT =
  process.env.GATSBY_API_BASE_CLIENT === 'true' ? true : false

interface Props {
  active: boolean
}

interface ILinksProps {
  active: boolean
}

interface ExternalLinksProps {
  href: string
}

const LinksWrapper = s.div<ILinksProps>`
  padding-top: 10px;
  flex: 1;
  text-align: right;

  ${maxWidth(PHONE)} {
    padding-top: 0px;
    flex: none;
    text-align: left;
    display: block;
    overflow: hidden;
    transition: max-height ${SHORT_ANIMATION_DURATION}ms ease, opacity ${SHORT_ANIMATION_DURATION}ms ease;
    max-height: ${({ active }): string => (active ? '100vh' : '0')};
    opacity: ${({ active }): string => (active ? '1' : '0')};
  }
`

const Spacer = s.div<{}>`
  display: none;

  ${maxWidth(PHONE)} {
    display: block;
    width: 100%;
    height: 0.5rem;
  }
`

const StyledLink = s(Link)`
  font-weight: ${MEDIUM_FONT_WEIGHT};
  height: 2rem;
  line-height: 2rem;
  margin-right: 1rem;
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
  transition: opacity ${SHORT_ANIMATION_DURATION}ms ease;
  color: ${PRIMARY};

  &:visited {
    color: ${PRIMARY};
  }

  &:hover
  &:active {
    color: ${SECONDARY};
    opacity: 0.7;
    text-decoration: none;
  }

  ${minWidth(DESKTOP)} {
    margin-right: calc(1rem + 1.25vw);
  }

  ${maxWidth(PHONE)} {
    margin-right: 0;
    width: 100%;
    text-align: center;
    display: block;
    margin: ${M1} 0;
  }
`

const StyledExternalLink = s.a<ExternalLinksProps>`
  font-weight: ${MEDIUM_FONT_WEIGHT};
  height: 2rem;
  line-height: 2rem;
  margin-right: 1rem;
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
  transition: opacity ${SHORT_ANIMATION_DURATION}ms ease;
  color: ${PRIMARY};

  &:visited {
    color: ${PRIMARY};
  }

  &:hover
  &:active {
    color: ${SECONDARY};
    opacity: 0.7;
    text-decoration: none;
  }

  ${minWidth(DESKTOP)} {
    margin-right: calc(1rem + 1.25vw);
  }

  ${maxWidth(PHONE)} {
    margin-right: 0;
    width: 100%;
    text-align: center;
    display: block;
    margin: ${M1} 0;
  }
`

const BtnWrapper = s.span`
  margin-top: -0.2rem;
  margin-bottom: -0.2rem;

  ${maxWidth(PHONE)} {
    margin: 0;
  }
`

const StyledBtnLink = s(BtnLink)`
  padding: ${M1} ${M2};
  margin: 0;

  ${maxWidth(PHONE)} {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: ${M3};
    margin-bottom: ${M2};
  }
`

export const Links = ({ active }: Props): ReactElement => {
  const isLoggedIn = session.isLoggedIn()
  const isTrainer = session.isTrainer()
  const isAdmin = session.isAdmin()
  const [additionalHeaderLinksData, setAdditionalHeaderData] = useState<
    AdditionalHeaderLinks[]
  >([])

  useEffect(() => {
    const getAdditionalHeaderLinks = async () => {
      const additionalHeaderLinks = await fetchAdditionalHeaderLinks()
      setAdditionalHeaderData(additionalHeaderLinks)
    }

    if (isLoggedIn) {
      getAdditionalHeaderLinks()
    }
  }, [isLoggedIn])

  return (
    <LinksWrapper active={active}>
      <Spacer />
      {!!!isLoggedIn ? (
        <>
          {LIVE_FEATURE_TOGGLE &&
            GATSBY_API_BASE_CLIENT &&
            !HIDE_LIVE_MENU_LINK && (
              <StyledLink
                activeStyle={{ color: SECONDARY, fontWeight: '500' }}
                to={BASIC_LIVE_SCHEDULE}
              >
                Live Classes
              </StyledLink>
            )}

          <StyledLink
            activeStyle={{ color: SECONDARY, fontWeight: '500' }}
            to={HOME_ROUTE}
          >
            Sign Up Now
          </StyledLink>
          <BtnWrapper>
            <StyledBtnLink
              to={LOGIN_ROUTE}
              size={EBtnSize.SM}
              kind={EBtnKind.Danger}
            >
              Login
            </StyledBtnLink>
          </BtnWrapper>
        </>
      ) : (
        <>
          {isAdmin && (
            <StyledLink
              activeStyle={{ color: SECONDARY, fontWeight: '500' }}
              to={DASHBOARD}
            >
              Dashboard
            </StyledLink>
          )}

          {!LANDING_PAGE_ONLY_TOGGLE && (
            <>
              <StyledLink
                activeStyle={{ color: SECONDARY, fontWeight: '500' }}
                to={WORKOUTS_ROUTE}
              >
                Workouts
              </StyledLink>
              {LIVE_FEATURE_TOGGLE && !isTrainer && (
                <StyledLink
                  activeStyle={{ color: SECONDARY, fontWeight: '500' }}
                  to={BASIC_LIVE_SCHEDULE}
                >
                  Live Schedule
                </StyledLink>
              )}

              {isTrainer && (
                <StyledLink
                  activeStyle={{ color: SECONDARY, fontWeight: '500' }}
                  to={
                    LIVE_FEATURE_TOGGLE
                      ? TRAINER_SCHEDULE
                      : TRAINER_UPLOAD_VIDEO
                  }
                >
                  Manage Site
                </StyledLink>
              )}

              {!isTrainer && (
                <StyledLink
                  activeStyle={{ color: SECONDARY, fontWeight: '500' }}
                  to={PROFILE_ROUTE}
                >
                  Profile
                </StyledLink>
              )}
            </>
          )}

          {!isTrainer &&
            !!additionalHeaderLinksData.length &&
            additionalHeaderLinksData.map(headerLink => (
              <StyledExternalLink href={headerLink.link} key={headerLink.link}>
                {headerLink.label}
              </StyledExternalLink>
            ))}

          {!isTrainer && (
            <StyledLink
              activeStyle={{ color: SECONDARY, fontWeight: '500' }}
              to={MORE_ROUTE}
            >
              Manage Account
            </StyledLink>
          )}

          <StyledLink
            activeStyle={{ color: SECONDARY, fontWeight: '500' }}
            onClick={() => {
              session.logout()
            }}
            to={LOGIN_ROUTE}
          >
            Logout
          </StyledLink>
        </>
      )}
    </LinksWrapper>
  )
}
