import styled from 'styled-components'
import { BACKGROUND, PRIMARY } from '../../constants/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  border-top: 1px solid #747474;
  background-color: ${BACKGROUND};
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  padding: 0rem 3rem 0.5rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Primary = styled.div`
  font-size: 16px;
  color: ${PRIMARY};
  margin-bottom: 12px;
  cursor: pointer;
  padding: 0px 12px;
`

export const ItemsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  padding: 0px 12px;
`

export const IconWrap = styled.div`
  cursor: pointer;
`

export const Secondary = styled.div`
  font-size: 20px;
  color: ${PRIMARY};
  padding-right: 24px;
  cursor: default;
`
