import React from 'react'
import s from 'styled-components'

import { minWidth, PHONE } from '../../../constants/measurements'
import { SECONDARY } from '../../../constants/colors'

const Wrapper = s.div`
  padding: 10px 1rem;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0.6rem;

  &:hover {
    opacity: 0.5;
  }

  ${minWidth(PHONE)} {
    display: none;
  }
`

const Bar = s.span`
  width: 16px;
  height: 2px;
  margin: 3px 0;
  display: block;
  background: ${SECONDARY};
`

interface IBarsProps {
  handleClick: (event: React.MouseEvent) => void
}

export const Bars = ({ handleClick }: IBarsProps): React.ReactElement => (
  <Wrapper onClick={handleClick}>
    <Bar />
    <Bar />
    <Bar />
  </Wrapper>
)
