import request from '../../services/request'
import { API_GET_ADDITIONAL_HEADER_LINKS } from '../apiRoutes'

export interface AdditionalHeaderLinks {
    label: string
    link: string
}

export const fetchAdditionalHeaderLinks = async (): Promise<AdditionalHeaderLinks[]> =>
    request(API_GET_ADDITIONAL_HEADER_LINKS(), {}, {}, true).then(res => res as AdditionalHeaderLinks[])

