import React from 'react'
import styled from 'styled-components'

import Footer from '../components/Footer'
import { Header } from '../components/shared/Header'
import { BACKGROUND } from '../constants/colors'

import './layout.css'

export const Main = styled.div`
  min-height: 100vh;
  background: ${BACKGROUND};
  margin-bottom: -36px;
  padding-bottom: 25px;
`

interface Props {
  children: React.ReactNode
}

const Layout = ({ children }: Props): React.ReactElement => (
  <>
    <Header />
    <Main>{children}</Main>
    <Footer />
  </>
)

export default Layout
