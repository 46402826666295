import * as Sentry from '@sentry/react'
import React, { useState } from 'react'
import { createGlobalStyle } from 'styled-components'

import * as Colors from '../../constants/colors'
import Layout from '../../templates/Layout'
import * as session from '../../util/session'

// import WebFont from 'webfontloader'

Sentry.init({
  dsn:
    'https://590dab8dbce340d08c7feef286f23507@o373149.ingest.sentry.io/5567473',
  // autoSessionTracking: true,
  // integrations: [new Integrations.BrowserTracing()],
  // tracesSampleRate: 1.0,
})

const FONT_FAMILY =
  process.env.GATSBY_FONT_FAMILY !== undefined
    ? process.env.GATSBY_FONT_FAMILY
    : 'Roboto'

// WebFont.load({
//   google: {
//     families: [FONT_FAMILY, 'Roboto'],
//   },
// })

const GlobalStyle = createGlobalStyle`
  ${() => `
    body {
      font-family: ${FONT_FAMILY}, 'Roboto';
    }
  `}
`

interface Props {
  error: Error
}

const FallbackComponent = ({ error }: Props) => {
  const [showMore, setShowMore] = useState(false)
  return (
    <Layout>
      <div
        style={{
          color: Colors.themeColors.textColor,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 200,
          flexDirection: 'column',
        }}
      >
        <b>An eror has occured</b> <br />
        <div
          style={{ cursor: 'pointer', color: Colors.BLUE_01 }}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show Less' : 'Show More'}
        </div>
        {showMore && (
          <>
            <b>Error:</b> {error.message} <br />
          </>
        )}
      </div>
    </Layout>
  )
}

export function withErrorHandler(Component) {
  class WithErrorHandler extends React.Component {
    state = {
      error: null,
    }

    componentDidMount() {
      const favicon = process.env.GATSBY_FAVICON
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        // @ts-ignore
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      if (favicon !== undefined) {
        // @ts-ignore
        link.href = favicon
      }
    }

    render() {
      return (
        <>
          <Sentry.ErrorBoundary
            onError={error => this.setState({ error })}
            fallback={<FallbackComponent error={this.state.error} />}
            beforeCapture={scope => {
              const user = session.getUserData()
              scope.setTag('email', user ? user.email : null)
              scope.setTag('userId', session.getId())
            }}
          >
            <GlobalStyle />
            <Component {...this.props} />
          </Sentry.ErrorBoundary>
        </>
      )
    }
  }
  return WithErrorHandler
}
