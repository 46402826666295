import React, { useState } from 'react'
import s from 'styled-components'

import { BACKGROUND, BORDER } from '../../../constants/colors'
import {
  maxWidth,
  PHONE,
  HEADER_HEIGHT,
  HEADER_Z_INDEX,
} from '../../../constants/measurements'
import { Container } from '../Grid'
import { Logo } from './Logo'
import { Bars } from './Bars'
import { Links } from './Links'
import { Shade } from '../Shade'

const Nav = s.nav`
  padding-top: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${BORDER};
  position: fixed;
  top: 0;
  z-index: ${HEADER_Z_INDEX};
  width: 100%;
  background: ${BACKGROUND};
  min-height: ${HEADER_HEIGHT};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
`

const StyledContainer = s(Container)`
  display: flex;
  flex-direction: row;

  ${maxWidth(PHONE)} {
    display: block;
  }
`

const NavSpace = s.div`
  height: ${HEADER_HEIGHT};
  width: 100%;
  display: block;
`

export const Header = (): React.ReactElement => {
  const [isNewlyMounted, setIsNewlyMounted] = useState<boolean>(true)
  const [active, setActive] = useState<boolean>(false)

  const toggle = (): void => {
    if (isNewlyMounted) setIsNewlyMounted(false)
    setActive(!active)
  }

  return (
    <>
      <Nav>
        <StyledContainer>
          <Logo />
          <Bars handleClick={toggle} />
          <Links active={active} />
        </StyledContainer>
      </Nav>
      <NavSpace />
      <Shade
        tabIndex={-1}
        zIndex={HEADER_Z_INDEX - 1}
        isNewlyMounted={isNewlyMounted}
        show={active}
        onClick={toggle}
      />
    </>
  )
}
